import React, { createContext, useContext, useReducer } from 'react';

import produce from 'immer';

const initState: State = {
  dialog: {
    open: false,
    activeTab: 0,
    rulesTab: { disabled: true },
    addPolicyButton: { disabled: true },
  },
};

const CreatePolicyStateContext = createContext<[State, React.Dispatch<Action>]>([
  initState,
  () => {
    throw new Error('[CreatePolicyStateContext]: Reducer not implemented');
  },
]);

export default function CreatePolicyStateProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <CreatePolicyStateContext.Provider value={useReducer(reducer, initState)}>
      {children}
    </CreatePolicyStateContext.Provider>
  );
}

export const useCreatePolicyState = () => useContext(CreatePolicyStateContext);

type State = {
  dialog: {
    open: boolean;
    activeTab: number;
    rulesTab: { disabled: boolean };
    addPolicyButton: { disabled: boolean };
  };
};

type Action =
  | { type: 'open_dialog' }
  | { type: 'close_dialog' }
  | {
      type: 'set_active_tab';
      payload: number;
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open_dialog':
      return produce(state, draft => {
        draft.dialog.open = true;
      });
    case 'close_dialog':
      return produce(state, draft => {
        draft.dialog.open = false;
      });
    case 'set_active_tab':
      return produce(state, draft => {
        draft.dialog.activeTab = action.payload;
      });
    default:
      return state;
  }
}
