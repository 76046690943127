import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import StepCard from 'client/app/apps/protocols/StepCard/StepCard';
import { useProtocolStep } from 'client/app/apps/protocols/useProtocolStep';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import Button from 'common/ui/components/Button';

export const DefineProtocol = () => {
  const [selectedObjectIds, setSelectedObjects] = useState<string[]>([]);
  const { addNewStep, steps, workflowId } = useProtocolContext();
  const { getStepProps } = useProtocolStep();

  // TODO: Replace with the final implementation once the ProtocolElementInstancePanel is merged.
  // This is temporary code to demonstrate that retrieving the relevant data is possible.
  useEffect(() => {
    if (selectedObjectIds.length === 1) {
      alert(selectedObjectIds[0]);
    }
  }, [selectedObjectIds]);

  return (
    <Content>
      <SidePanel>
        <Typography variant="h2">Define Protocol</Typography>
        {steps.map(step => (
          <StepCard
            key={step.id}
            {...getStepProps(step)}
            // TODO: I left those out for now
            // Not sure how to best address those. Will update once we get to it.
            onDeleteInput={() => {}}
            onDeleteOutput={() => {}}
          />
        ))}
        <Button variant="primary" color="primary" onClick={addNewStep}>
          + Add a step
        </Button>
      </SidePanel>
      <PreviewWrapper>
        <WorkflowPreview
          workflowId={workflowId}
          setSelectedObjects={setSelectedObjects}
          selectedObjectIds={selectedObjectIds}
        />
      </PreviewWrapper>
    </Content>
  );
};

const Content = styled('div')(() => ({
  display: 'flex',
  height: '100%',
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  display: 'flex',
  minWidth: '350px',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(6, 4),
}));

const PreviewWrapper = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
}));
